import React from "react";
import { Link, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import useSiteMetadata from "../components/SiteMetadata";
import {
  sizes,
  colorAliases,
  colors,
  fontSizes,
  borderRadius,
  boxShadow,
  MOBILEBREAKPOINT,
  appPalette,
} from "../style/design-system";
import Layout from "../components/Layout";
import OuterContainer from "../components/OuterContainer";
import { CtaButton } from "../components/CtaButton";

const StyledCtaButton = styled(CtaButton)`
  padding: ${sizes.md} ${sizes.xl};
  margin-bottom: ${sizes.xl};

  @media (max-width: ${MOBILEBREAKPOINT}) {
    padding: ${sizes.sm} ${sizes.lg};
  }
`;

const SectionInnerContainer = styled.div`
  width: 100%;
  max-width: ${sizes.siteWidth};
  margin: 0 auto;
  padding: ${sizes["2xl"]} ${sizes.lg} ${sizes.lg} ${sizes.lg};

  @media (max-width: ${MOBILEBREAKPOINT}) {
    padding: ${sizes.lg};
  }
`;

const SectionHeader = styled.h3`
  font-weight: 700;
  font-size: ${fontSizes["3xl"]};
  margin-bottom: ${sizes.md};

  @media (max-width: ${MOBILEBREAKPOINT}) {
    font-size: ${fontSizes["2xl"]};
    margin: 0 0 ${sizes.xs} 0;
  }
`;

const SmallHeader = styled.h4`
  font-weight: 700;
  font-size: ${fontSizes["2xl"]};
  text-align: center;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    font-size: ${fontSizes["xl"]};
  }
`;

const ContactLink = styled.span`
  display: inline-block;
  line-height: 1;
  border-bottom: 2px solid ${colors.gray["200"]};
  cursor: pointer;

  &:hover {
    border-bottom: 2px solid ${colorAliases.textColor};
  }
`;

const SectionTagline = styled.h4`
  margin: 0 auto ${sizes["2xl"]} auto;
  font-weight: 300;
  font-size: ${fontSizes.lg};
  line-height: 1.5;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    margin: 0 auto ${sizes.xl} auto;
  }
`;

const BeigeBackground = styled.div`
  background: ${colorAliases.beige};
  grid-column: 1 / -1;
  grid-row: 1 / 4;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    grid-row: 1 / -1;
  }
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${sizes["4xl"]};
  margin: ${sizes["2xl"]} 0;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    grid-template-columns: 1fr;
    grid-gap: ${sizes.lg};
    margin: 0 0 ${sizes["2xl"]} 0;
  }
`;

const Card = styled.div`
  background: white;
  max-width: 70ch;
  overflow: hidden;
`;

const CardHeader = styled.header`
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${sizes.md} 0 ${sizes.md} 0;
`;

const Logo = styled.img`
  display: block;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;

const CardBody = styled.div`
  background: white;
`;

const TestimonialText = styled.p`
  border-style: solid;
  border-width: 2px;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    to right,
    ${colorAliases.turqoise},
    white 50%
  );
  border-top: none;
  font-size: ${fontSizes.md};
  font-weight: 300;
  background: white;
  padding: 0 ${sizes.lg} ${sizes.lg} ${sizes.lg};
  margin: 0;
  flex: 20;
`;

const Signature = styled.p`
  text-align: right;
  font-size: ${fontSizes.md};
  font-weight: 300;
  padding: ${sizes.xxs} 0 0 0;
  margin: 0;
`;

const CustomerLink = styled.a`
  color: ${colorAliases.textColor};
  text-decoration: underline;

  &:visited {
    color: ${colorAliases.textColor};
  }
`;

const CustomerCard = ({ customer }) => {
  return (
    <Card>
      <CardHeader>
        <Logo src={`/img/${customer.logo.relativePath}`} />
      </CardHeader>
      <CardBody>
        <TestimonialText>{customer.testimonial}</TestimonialText>
        <Signature>
          {customer.personName} <br></br> {customer.personTitle} <br></br>{" "}
          {customer.link ? (
            <CustomerLink
              rel="noopener noreferer"
              target="_blank"
              href={customer.link}
            >
              {customer.companyName}
            </CustomerLink>
          ) : (
            customer.companyName
          )}
        </Signature>
      </CardBody>
    </Card>
  );
};

export const CustomersPageTemplate = ({
  location,
  title,
  subtitle,
  metaTitle,
  customers,
}) => {
  const { title: siteTitle, siteUrl } = useSiteMetadata();

  return (
    <div>
      <Helmet>
        <title>{`${metaTitle} | ${siteTitle}`}</title>
        <link rel="canonical" href={siteUrl + location.pathname} />
      </Helmet>
      <OuterContainer>
        <SectionInnerContainer>
          <SectionHeader>{title}</SectionHeader>
          <SectionTagline style={{ marginBottom: sizes.md }}>
            {subtitle}
          </SectionTagline>
          <CardContainer>
            {customers.customer.map((customer) => (
              <CustomerCard customer={customer} />
            ))}
          </CardContainer>
        </SectionInnerContainer>
      </OuterContainer>
    </div>
  );
};

const CustomersPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout language={frontmatter.language}>
      <CustomersPageTemplate
        location={location}
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        metaTitle={frontmatter.metaTitle}
        customers={frontmatter.customers}
      />
    </Layout>
  );
};

export default CustomersPage;

export const pageQuery = graphql`
  query CustomersPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        metaTitle
        language
        title
        subtitle
        customers {
          customer {
            companyName
            logo {
              relativePath
            }
            link
            personName
            personTitle
            testimonial
          }
        }
      }
    }
  }
`;
