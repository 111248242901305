import React from "react";
import styled from "styled-components";
import {
  sizes,
  colorAliases,
  fontSizes,
  borderRadius,
  boxShadow,
} from "../style/design-system";

export const CtaButton = styled.button`
  background: transparent;
  border-radius: ${borderRadius.md};
  border: 1px solid ${colorAliases.red};
  color: ${colorAliases.red};
  padding: ${sizes.md} ${sizes.xl};
  font-size: ${fontSizes.lg};
  cursor: pointer;
  margin: 0 auto;
  box-shadow: ${boxShadow.md};
`;
